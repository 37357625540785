import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import Img from 'gatsby-image'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext

    const alert = {
      width: '100%',
      margin: '0 0.9% 1.5%',
      color: 'orange',
    }

    return (
      <DefaultLayout>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <article style={alert}>
          <p>
            The following content is for ADULTS, focused on PonyPlay and other
            BDSM practices. Please leave this web site if you are underage, or
            if this type of content is illegal in your country.
          </p>
        </article>
        <article className="article-page">
          <div className="page-content">
            {post.frontmatter.img && (
              <div className="page-cover-image">
                <figure>
                  <Img
                    className="page-image"
                    key={post.frontmatter.img.childImageSharp.fluid.src}
                    fluid={post.frontmatter.img.childImageSharp.fluid}
                  />
                </figure>
              </div>
            )}
            <div className="wrap-content">
              <header className="header-page">
                <h1 className="page-title">{post.frontmatter.title}</h1>
                <div className="page-date">
                  <span>{post.frontmatter.category}</span>
                </div>
              </header>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </article>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        category
        img {
          childImageSharp {
            fluid(maxWidth: 3720) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
